import React, { useState } from 'react';
import { useRouter } from 'next/router';

const NotFoundPage = () => {
  const router = useRouter();

  return (
    <div className={`p-2 mb-3 flex justify-center items-center h-screen`}>
      <div className="text-2xl">Хуудас олдсонгүй</div>
    </div>
  );
};

export default NotFoundPage;
